import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PersonalData = _resolveComponent("PersonalData", true)!
  const _component_CancelAccount = _resolveComponent("CancelAccount")!
  const _component_master_layout = _resolveComponent("master-layout")!

  return (_openBlock(), _createBlock(_component_master_layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_PersonalData, { id: "personalDataContainer" }),
      _createVNode(_component_CancelAccount, { id: "cancelAccountContainer" })
    ]),
    _: 1
  }))
}